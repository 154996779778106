.active-icon-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: dodgerblue;
}
.active-icon-import {
  display: flex;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-sizing: border-box;
  width: 95%;
  gap: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.icon-container {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 20px 0px;
}

.code-snippet .mantine-Prism-lineContent,
.code-snippet .mantine-Prism-lineNumber {
  font-size: 9px;
}
.icon-data {
  height: calc(100% - 110px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon-color-list {
  display: flex;
  gap: 15px;
  box-sizing: border-box;
  padding: 10px 15px;
  background: #f7f7f7;
  border-radius: 5px;
  flex-wrap: wrap;
}
