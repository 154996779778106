body {
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.app {
  margin: 10px;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}
body.anthony-media-header-disabled .app {
  margin-top: 10px;
}
.app * {
  font-family: sans-serif !important;
}
.app code {
  font-family: monospace !important;
}
.app {
  font-family: sans-serif;
}
.app .title {
  text-align: center;
}
.app .icons-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: auto;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid #dee2e6;
  border-radius: 0px 0px 15px 15px;
  padding-bottom: 10px;
  height: 100%;
}
.app .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;
  margin-bottom: 0px;
  padding: 10px;
  width: 100px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer !important;
  border-radius: 20px;
}

.app .icon:hover {
  background: #f7f7f7;
}
.app .icon label {
  font-size: 0.75em;
  color: rgb(11, 51, 90);
  padding: 5px;
  margin: 10px;
  margin-bottom: 0px;
  background-color: rgba(30, 143, 255, 0.178);
  border-radius: 5px;
  cursor: pointer;
}
.app .icon:active .icon-svg {
  transform: scale(0.75);
}

.app .theme-chip {
  cursor: pointer;
  border: 1px solid;
}

.app *::-webkit-scrollbar {
  width: 16px;
}
.app *::-webkit-scrollbar-thumb {
  background: #dadce0;
  background-clip: padding-box;
  border: 4px solid transparent;
  border-radius: 8px;
  box-shadow: none;
  min-height: 50px;
}
.app *::-webkit-scrollbar-track {
  background: none;
  border: none;
}

.icons-list-container {
  position: relative;
  box-sizing: border-box;
}
.icons-list-dimmed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* margin: 5px; */
  box-sizing: border-box;
  z-index: 1;
  background-color: rgba(218, 220, 224, 0.5);
  opacity: 0;
  pointer-events: none;
}
.icons-list-dimmed.active {
  opacity: 1;
  pointer-events: all;
}
